.projects-container {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 80%;
  overflow: scroll;
  padding: 20px;
  border: solid 2px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  top: 88px;
  right: 82px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.projects-container::-webkit-scrollbar {
  display: none;
}

.projects-container-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  /* border: solid 1px red; */
}

.projects-container-close-icon {
  width: 20px;
  height: 20px;
}

.projects-close-icon {
  cursor: pointer;
}

.projects-close-icon:hover {
  transform: scale(1.1);
}

.projects-container-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.projects-container-body::-webkit-scrollbar {
  display: none;
}
/* ---------- ANIMATION----------- */
.projects-container {
  animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.content {
  z-index: 1;
  animation: blowUpContent 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) backwards;
}

@keyframes blowUpContent {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  99.9% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(0);
  }
}

@keyframes blowUpModal {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* ---------- MOBILE SECTION ----------- */
@media only screen and (max-width: 600px) {
  .projects-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    height: 80%;
    overflow: scroll;
    padding: 20px;
    border: solid 2px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    top: 70px;
    right: 30px;
  }

  .projects-container-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-bottom: solid 1px rgba(0, 0, 0, 0.5);
  }

  .projects-container-close-icon {
    width: 20px;
    height: 20px;
  }

  .projects-close-icon {
    cursor: pointer;
  }

  .projects-close-icon:hover {
    transform: scale(1.1);
  }

  .projects-container-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
}
