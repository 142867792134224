:root {
  --black: #222831;
  --dark-gray: #393e46;
  --orange: #fd7014;
  --white: #eeeeee;
}

.demos-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.demos-header-section {
  display: flex;
  width: 100%;
}

.demos-left-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.demos-logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 10%;
  margin-left: 80px;
  margin-top: 15px;
  margin-right: 20px;
  cursor: pointer;
}

.demos-logo {
  width: 100%;
}

.demos-right-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}

.demos-option {
  color: var(--white);
  font-weight: 500;
  cursor: pointer;
}

.demos-option:hover {
  transform: scale(1.04);
}

.demos-icon-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 25%;
  margin-right: 80px;
}

.demos-icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.demos-icon:hover {
  transform: scale(1.1);
}

.demos-projects-icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  cursor: pointer;
  margin-top: 20px;
}

.demos-projects-icon-wrapper:hover {
  transform: scale(1.1);
}

.demos-projects-icon {
  width: 50px;
  height: 50px;
}

/* ---------- MOBILE SECTION ----------- */
@media only screen and (max-width: 600px) {
}
