@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Oswald:wght@200;300;400;500;600&display=swap');

.css-demo-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
}

.css-demo-container::-webkit-scrollbar {
  display: none;
}

/* HEADER */
.css-demo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 300px;
  position: relative;
  background-image: url('https://cdn.pixabay.com/photo/2017/03/05/00/34/panorama-2117310_1280.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 100% 80%;
  border-bottom-right-radius: 100% 80%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.css-header-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 2rem;
  color: white;
  margin-left: 1rem;
}

/* BODY */
.css-demo-body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.css-demo-body-row {
  display: flex;
  width: 100%;
}

.css-demo-body-column {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 1%;
}

.image-col {
  justify-content: center;
  align-items: center;
}

.text-col {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5%;
  /* outline: solid 1px red; */
}

.css-demo-body-text-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
}

.css-demo-body-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  line-height: 1.2rem;
  font-size: 1rem;
}

.css-body-image-wrapper {
  display: flex;
  width: 70%;
}

.css-demo-body-image {
  width: 100%;
  border-radius: 20px;
}

.css-demo-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.css-demo-icon:hover {
  transform: scale(1.1);
}

.css-body-icon-bar-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.css-large-image-body {
  width: 100%;
  border-radius: 20px;
  margin-top: 1%;
}

/* GALLERY */
.css-demo-gallery-container-outer {
  display: flex;
  max-height: 600px;
}

.css-demo-gallery-container-inner {
  columns: 3;
  margin: 10px;
  gap: 20px;
  padding: 15px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border: solid 1px black;
  border-radius: 20px;
}

.css-demo-gallery-container::-webkit-scrollbar {
  display: none;
}

.css-gallery-demo-img {
  width: 100%;
  border-radius: 20px;
  margin-bottom: 20px;
}

/* SPLASH PAGE */
.css-demo-splash-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url('https://cdn.pixabay.com/photo/2017/03/05/00/34/panorama-2117310_1280.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.css-demo-splash-page-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.css-demo-splash-page-image {
  width: 100%;
}

.css-demo-splash-page-header-text {
  font-family: 'Oswald', sans-serif;
  font-weight: 200;
  color: white;
  font-size: 5rem;
}

.css-demo-splash-page-form {
  background-color: white;
  display: flex;
  padding: 2px;
  width: 400px;
  height: 40px;
  border-radius: 20px;
  padding: 7px 8px;
  border: solid 1px transparent;
}

.css-demo-splash-page-search-input {
  font-family: 'Oswald', sans-serif;
  font-weight: 200;
  background-color: white;
  width: 380px;
  padding: 7px 8px;
  border: solid 1px transparent;
  outline: none;
}

.css-demo-splash-page-search-icon {
  width: 50px;
  cursor: pointer;
}

.css-demo-splash-page-footer {
  display: flex;
  margin-top: 5%;
}

.css-demo-splash-page-text-link {
  font-family: 'Oswald', sans-serif;
  font-weight: 200;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

/* ICON BAR */
.css-icon-bar-container {
  display: flex;
  justify-content: center;
  margin-top: 15%;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  width: 100%;
}

.css-icon-wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.css-icon-bar-icon {
  width: 2rem;
  cursor: pointer;
}

/* FOOTER */
.css-demo-footer {
  display: flex;
  bottom: 0;
  background-color: rgba(40, 140, 255, 0.4);
}

.css-demo-footer-img {
  width: 10%;
}

/* ---------- MOBILE SECTION ----------- */
@media only screen and (max-width: 600px) {
  .css-demo-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
  }

  .css-demo-container::-webkit-scrollbar {
    display: none;
  }

  /* HEADER */
  .css-demo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 300px;
    position: relative;
    background-image: url('https://cdn.pixabay.com/photo/2017/03/05/00/34/panorama-2117310_1280.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom-left-radius: 100% 80%;
    border-bottom-right-radius: 100% 80%;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }

  .css-header-text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 2rem;
    color: white;
    margin-left: 1rem;
  }

  /* BODY */
  .css-demo-body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .css-demo-body-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .css-demo-body-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1%;
  }

  .image-col {
    justify-content: flex-start;
    align-items: center;
  }

  .text-col {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5%;
  }

  .css-demo-body-text-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
  }

  .css-demo-body-text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    line-height: 1.2rem;
    font-size: 1rem;
  }

  .css-body-image-wrapper {
    display: flex;
    width: 70%;
  }

  .css-demo-body-image {
    width: 100%;
    border-radius: 20px;
  }

  .css-demo-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
  }

  .css-demo-icon:hover {
    transform: scale(1.1);
  }

  .css-body-icon-bar-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .css-large-image-body {
    width: 100%;
    border-radius: 20px;
    margin-top: 1%;
  }

  /* GALLERY */
  .css-demo-gallery-container-outer {
    display: flex;
    max-height: 600px;
  }

  .css-demo-gallery-container-inner {
    columns: 3;
    margin: 10px;
    gap: 20px;
    padding: 15px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border: solid 1px black;
    border-radius: 20px;
  }

  .css-demo-gallery-container::-webkit-scrollbar {
    display: none;
  }

  .css-gallery-demo-img {
    width: 100%;
    border-radius: 20px;
    margin-bottom: 20px;
  }

  /* SPLASH PAGE */
  .css-demo-splash-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    background-image: url('https://cdn.pixabay.com/photo/2017/03/05/00/34/panorama-2117310_1280.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .css-demo-splash-page-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .css-demo-splash-page-image {
    width: 100%;
  }

  .css-demo-splash-page-header-text {
    font-family: 'Oswald', sans-serif;
    font-weight: 200;
    color: white;
    font-size: 3rem;
  }

  .css-demo-splash-page-form {
    background-color: white;
    display: flex;
    padding: 2px;
    width: 350px;
    height: 40px;
    border-radius: 20px;
    padding: 7px 8px;
    border: solid 1px transparent;
  }

  .css-demo-splash-page-search-input {
    font-family: 'Oswald', sans-serif;
    font-weight: 200;
    background-color: white;
    width: 320px;
    padding: 7px 8px;
    border: solid 1px transparent;
    outline: none;
  }

  .css-demo-splash-page-search-icon {
    width: 50px;
    cursor: pointer;
  }

  .css-demo-splash-page-footer {
    display: flex;
    margin-top: 5%;
  }

  .css-demo-splash-page-text-link {
    font-family: 'Oswald', sans-serif;
    font-weight: 200;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }

  /* ICON BAR */
  .css-icon-bar-container {
    display: flex;
    justify-content: center;
    margin-top: 15%;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    width: 100%;
  }

  .css-icon-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .css-icon-bar-icon {
    width: 2rem;
    cursor: pointer;
  }

  /* FOOTER */
  .css-demo-footer {
    display: flex;
    bottom: 0;
    background-color: rgba(40, 140, 255, 0.4);
  }

  .css-demo-footer-img {
    width: 10%;
  }
}
