:root {
  --black: #222831;
  --dark-gray: #393e46;
  --orange: #fd7014;
  --white: #eeeeee;
}

.sidebar-container {
  display: flex;
  position: absolute;
  justify-content: center;
  width: 143px;
  top: 23px;
  left: 5px;
  outline: solid 1px white;
}

.sidebar-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  outline: solid 1px green;
}

.sidebar-text {
  color: var(--white);
  cursor: pointer;
  text-decoration: none;
  font-size: small;
  padding: 10px;
}

.sidebar-text:hover {
  color: var(--orange);
}
