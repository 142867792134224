:root {
  --black: #222831;
  --dark-gray: #393e46;
  --orange: #fd7014;
  --white: #eeeeee;
}

.algorithm-container {
  display: flex;
  color: var(--white);
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 80px;
  width: 100%;
  /* outline: solid 1px white; */
}
